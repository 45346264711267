import { render, staticRenderFns } from "./dispatch.vue?vue&type=template&id=60da7ee2&scoped=true"
import script from "./dispatch.vue?vue&type=script&lang=js"
export * from "./dispatch.vue?vue&type=script&lang=js"
import style0 from "./dispatch.vue?vue&type=style&index=0&id=60da7ee2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60da7ee2",
  null
  
)

export default component.exports